<template>
  <section class="views">
    <headers select="3"></headers>
    <div class="views_content">
      <div class="views_banner">
        <img :src="require('@/assets/image/hodgepodge/h5banner.jpg')" alt="" />
      </div>

      <div class="viewsBoxTab">
        <div class="viewsTab" ref="timeSlide">
          <ul>
            <li
              v-for="(item, index) in tabColumns"
              :key="index"
              @click="tabBut(index)"
              :class="select == index ? 'select' : ''"
              :ref="`tab${index}Ref`"
            >
              <div>{{ item.name }}</div>
            </li>
          </ul>
        </div>
      </div>
      <div class="shortlisted-query" v-if="select == 1">
        <div class="contentFrom_title">入圍名單查詢</div>
        <div class="plateUlsCentent6">
          <ul>
            <li>
              <div class="prompt" style="cursor: pointer">
                <div
                  :class="choose == 0 ? 'highlight' : ''"
                  @click="chooseBut(0)"
                >
                  個人報名
                </div>
                <div
                  :class="choose == 1 ? 'highlight' : ''"
                  @click="chooseBut(1)"
                >
                  學校報名
                </div>
              </div>

              <div class="row-input" v-if="choose == 0">
                <!-- <div class="input-name-1">參賽者電話</div> -->
                <div class="promptInput2">
                  <div class="lis">
                    <div class="lisLife">
                      <!-- <img :src="require('@/assets/image/hk.png')" />
										<span>+852</span> -->
                      <CountryCodeSelector
                        :searchbar="false"
                        :countryCode.sync="prefix"
                        style="width: 75px; margin-right: 5px"
                      ></CountryCodeSelector>
                    </div>
                    <input
                      class="lisRight"
                      type="text"
                      placeholder="請輸入參賽電話"
                      value=""
                      v-model.trim="from.phone"
                    />
                  </div>
                </div>
              </div>
              <div class="row-input" v-if="choose == 1">
                <!-- <div class="input-name">學校編號</div> -->
                <input
                  class="promptInput"
                  placeholder="請輸入學校編號"
                  v-model.trim="from.schoolCode"
                />
                <!-- <input
                  class="promptInput"
                  placeholder="請輸入參賽郵箱"
                  v-model.trim="from.email"
                /> -->
              </div>
            </li>
            <li v-if="choose == 0">
              <!-- <div class="prompt">姓名</div> -->
              <div class="row-input">
                <!-- <div class="input-name-1">參賽者姓名</div> -->
                <input
                  class="promptInput"
                  placeholder="請輸入參賽者姓名"
                  v-model="from.school_name"
                />
              </div>
            </li>
            <template v-if="choose == 1">
              <li>
                <div class="row-input">
                  <!-- <div class="input-name">學生編號</div> -->
                  <input
                    class="promptInput"
                    placeholder="請輸入學生編號"
                    v-model="from.studentCode"
                  />
                </div>
              </li>
              <li>
                <div class="row-input">
                  <!-- <div class="input-name">學生密碼</div> -->
                  <input
                    class="promptInput"
                    placeholder="請輸入學生密碼"
                    v-model="from.password"
                  />
                </div>
              </li>
            </template>
          </ul>

          <div
            class="but"
            @click="finalistsBut ? finalists() : ''"
            style="cursor: pointer"
          >
            查詢
          </div>
        </div>
      </div>
      <div class="viewTabContent">
        <div class="ulsTab" ref="tabSlideTwo">
          <ul>
            <li
              v-for="(item, index) in tabColumns[select].dataTab"
              :key="index"
              :class="selectTwo == index ? 'select' : ''"
              @click="tabButTwo(index)"
              :ref="`tab${index}RefTwo`"
            >
              <div>{{ item.name }}</div>
              <div>（{{ item.fzhu }}）</div>
            </li>
          </ul>
        </div>

        <div class="box">
          <ul v-if="false">
            <div class="wu">
              <img :src="require('@/assets/image/hodgepodge/wu.png')" alt="" />
              <span> 當前階段成績尚未揭曉哦 </span>
            </div>
          </ul>
          <ul v-if="select == 1 && selectTwo == 6">
            <li class="weiyitop">
              <div>參賽序號</div>
              <div>姓名</div>
              <div>電話/學號</div>
              <div>入圍/獎項</div>
            </li>
            <li class="lis" v-for="(item, index) in queryUserList" :key="index">
              <div>{{ item.competitionId }}</div>
              <div>{{ item.studentName }}</div>
              <div>{{ item.studentCode || item.phone }}</div>
              <div>
                {{ index < 3 ? '入圍決賽' : index > 2 ? '優異獎' : '' }}
              </div>
            </li>
          </ul>
          <ul v-else-if="select != 2">
            <li class="weiyitop">
              <div>參賽序號</div>
              <div>姓名</div>
              <div>電話/學號</div>
            </li>

            <li
              class="lis"
              v-for="(item, index) in queryUserList"
              :key="index"
              v-if="select != 2"
            >
              <div>{{ item.competitionId }}</div>
              <div>{{ item.studentName }}</div>
              <div>{{ item.studentCode || item.phone }}</div>
            </li>
          </ul>
          <ul v-else-if="select == 2">
            <li class="weiyitop">
              <div>參賽序號</div>
              <div>姓名</div>
              <div>電話/學號</div>
              <div>獎項</div>
            </li>

            <li class="lis" v-for="(item, index) in queryUserList" :key="index">
              <div>{{ item.competitionId }}</div>
              <div>{{ item.studentName }}</div>
              <div>{{ item.studentCode || item.phone }}</div>
              <div>{{ item.awards }}</div>
            </li>
          </ul>
          <!-- <ul v-if="select === 2">
            <li class="weiyitop">
              <div>獎項</div>
              <div>姓名</div>
              <div>電話</div>
            </li>
            <li class="lis" v-for="(item, index) in queryUserList" :key="index">
              <div>
                {{
                  item.ranking == 1
                    ? '金獎'
                    : item.ranking == 2
                    ? '銀獎'
                    : item.ranking == 3
                    ? '銅獎'
                    : item.ranking > 3
                    ? '優異獎'
                    : ''
                }}
              </div>
              <div>{{ item.studentName }}</div>
              <div>{{ item.phone }}</div>
            </li>
          </ul> -->
        </div>
      </div>
    </div>
    <!-- 未入圍/成功入園 -->
    <van-popup v-model="newShortZZC" :round="true" :lock-scroll="false">
      <div class="newShortBox">
        <div class="list" v-if="newShortActive == 1">
          <!-- <div class="lis lisCenten"> <van-image width="149px" :src="require('@/assets/image/fusai/yes.png')" /> </div> -->
          <div class="ulst">
            <ul>
              <li>姓名</li>
              <li>組別</li>
              <li>分數</li>
            </ul>
            <ul>
              <li>
                {{ userObj.studentName == null ? '' : userObj.studentName }}
              </li>
              <li>
                {{
                  userObj.competitionGroup == null
                    ? ''
                    : userObj.competitionGroup
                }}
              </li>
              <li>{{ userObj.score == null ? '' : userObj.score }}</li>
            </ul>
          </div>
          <div class="lis lisText" v-if="select == 0">
            恭喜您，成功入圍複賽，<br />請仔細閱讀入圍電郵，上載參賽視頻。
          </div>
          <div class="lis lisText" v-if="select == 1">
            <span v-if="userObj.finalStatus == 1">您的成績未能入圍決賽，<br />恭喜獲得優異獎。</span>
            <span v-else>恭喜您，成功入圍決賽，<br />請仔細閱讀入圍電郵。</span>
          </div>
          <div class="view_guide" @click="isRematch = true" v-if="select == 0">
            查看複賽指引
          </div>
        </div>

        <div class="list" v-if="newShortActive == 2">
          <div class="ulst">
            <ul>
              <li>姓名</li>
              <li>組別</li>
              <li>分數</li>
            </ul>
            <ul>
              <li>
                {{ userObj.studentName == null ? '' : userObj.studentName }}
              </li>
              <li>
                {{
                  userObj.competitionGroup == null
                    ? ''
                    : userObj.competitionGroup
                }}
              </li>
              <li>{{ userObj.score == null ? '' : userObj.score }}</li>
            </ul>
          </div>
          <div class="lis lisText" v-if="select == 0">
            很遺憾，您的成績未能入圍複賽，<br />非常感謝您的參與。
          </div>
          <div class="lis lisText" v-if="select == 1">
            很遺憾，您的成績未能入圍決賽，<br />非常感謝您的參與。
          </div>
        </div>
      </div>
    </van-popup>
    <!-- 複賽指引 -->
    <RematchModal
      style="z-index: 9999"
      v-if="isRematch"
      @cancel="isRematch = false"
    ></RematchModal>
  </section>
</template>

<script>
import headers from '@/components/header/h_header'
import CountryCodeSelector from '@/components/CountryCodeSelector'
import RematchModal from '@/components/rematchModal/index.vue'
export default {
  name: 'views',
  data() {
    return {
      isRematch: false,
      tabColumns: [
        {
          id: '0',
          name: '初賽成績',
          dataTab: [
            {
              name: '幼稚園初級組',
              fzhu: 'K1-K2'
            },
            {
              name: '幼稚園高級組',
              fzhu: 'K3'
            },
            {
              name: '小學初級組',
              fzhu: 'P1-P2'
            },
            {
              name: '小學中級組',
              fzhu: 'P3-P4'
            },
            {
              name: '小學高級組',
              fzhu: 'P5-P6'
            },
            {
              name: '初中組',
              fzhu: 'S1-S3'
            },
            {
              name: '閃亮組',
              fzhu: '特殊學校'
            }
          ]
        },
        {
          id: '1',
          name: '複賽成績',
          dataTab: [
            {
              name: '幼稚園初級組',
              fzhu: 'K1-K2'
            },
            {
              name: '幼稚園高級組',
              fzhu: 'K3'
            },
            {
              name: '小學初級組',
              fzhu: 'P1-P2'
            },
            {
              name: '小學中級組',
              fzhu: 'P3-P4'
            },
            {
              name: '小學高級組',
              fzhu: 'P5-P6'
            },
            {
              name: '初中組',
              fzhu: 'S1-S3'
            },
            {
              name: '閃亮組',
              fzhu: '特殊學校'
            }
          ]
        },
        {
          id: '2',
          name: '決賽成績',
          dataTab: [
            {
              name: '幼稚園初級組',
              fzhu: 'K1-K2'
            },
            {
              name: '幼稚園高級組',
              fzhu: 'K3'
            },
            {
              name: '小學初級組',
              fzhu: 'P1-P2'
            },
            {
              name: '小學中級組',
              fzhu: 'P3-P4'
            },
            {
              name: '小學高級組',
              fzhu: 'P5-P6'
            },
            {
              name: '初中組',
              fzhu: 'S1-S3'
            },
            {
              name: '閃亮組',
              fzhu: '特殊學校'
            }
          ]
        }
      ],
      select: 2,
      selectTwo: 0,
      choose: 0, //查詢的下標
      from: {
        school_name: null,
        email: null,
        phone: null,
        schoolCode: null,
        studentCode: null,
        password: null
      },
      prefix: 852,
      finalistsBut: true,
      newShortZZC: false, //入圍 彈窗
      newShortActive: 0,
      userObj: {
        competitionGroup: 1, //組別
        score: null, //分數
        studentName: null //名字
      },
      queryUserList: [],
      finalists: [
        {
          group: 1,
          data: [
            {
              competitionId: 12712,
              studentName: '梁*然',
              studentCode: null,
              phone: '****9195',
              awards: '金獎'
            },
            {
              competitionId: 14860,
              studentName: '鄭*禮',
              studentCode: null,
              phone: '****4902',
              awards: '銀獎'
            },
            {
              competitionId: 15793,
              studentName: '陳*晴',
              studentCode: '*17',
              phone: '',
              awards: '銀獎'
            },
            {
              competitionId: 15948,
              studentName: '陸*翌',
              studentCode: null,
              phone: '****8570',
              awards: '銅獎'
            },
            {
              competitionId: 7155,
              studentName: '吳*澤',
              studentCode: null,
              phone: '****9856',
              awards: '銅獎'
            },
            {
              competitionId: 14799,
              studentName: '蘭*汐',
              studentCode: null,
              phone: '****5853',
              awards: '銅獎'
            },
            {
              competitionId: 9847,
              studentName: '梁*妍',
              studentCode: null,
              phone: '****8719',
              awards: '優異獎'
            },
            {
              competitionId: 16156,
              studentName: '林*晞',
              studentCode: null,
              phone: '****6064',
              awards: '優異獎'
            },
            {
              competitionId: 7268,
              studentName: '錢*逸',
              studentCode: null,
              phone: '****0009',
              awards: '優異獎'
            },
            {
              competitionId: 16167,
              studentName: '柏*嘉',
              studentCode: null,
              phone: '****8180',
              awards: '優異獎'
            }
          ]
        },
        {
          group: 2,
          data: [
            {
              competitionId: 15435,
              studentName: '徐*寧',
              studentCode: null,
              phone: '****6159',
              awards: '金獎'
            },
            {
              competitionId: 9829,
              studentName: '李*穎',
              studentCode: null,
              phone: '****0608',
              awards: '銀獎'
            },
            {
              competitionId: 9830,
              studentName: '姚*博',
              studentCode: null,
              phone: '****0608',
              awards: '銀獎'
            },
            {
              competitionId: 10735,
              studentName: '馬*小',
              studentCode: null,
              phone: '****1415',
              awards: '銅獎'
            },
            {
              competitionId: 15556,
              studentName: '陳*正',
              studentCode: '*-8',
              phone: '',
              awards: '銅獎'
            },
            {
              competitionId: 16273,
              studentName: '葉*正',
              studentCode: null,
              phone: '****1335',
              awards: '銅獎'
            },
            {
              competitionId: 7525,
              studentName: '邱*寧',
              studentCode: '*21',
              phone: '',
              awards: '優異獎'
            },
            {
              competitionId: 15611,
              studentName: '秦*棋',
              studentCode: '*25',
              phone: '',
              awards: '優異獎'
            },
            {
              competitionId: 10704,
              studentName: '陳*琪',
              studentCode: '*10',
              phone: '',
              awards: '優異獎'
            },
          ]
        },
        {
          group: 3,
          data: [
            {
              competitionId: 7166,
              studentName: '徐*躍',
              studentCode: null,
              phone: '****1990',
              awards: '金獎'
            },
            {
              competitionId: 16006,
              studentName: '林*宜',
              studentCode: null,
              phone: '****9539',
              awards: '銀獎'
            },
            {
              competitionId: 11473,
              studentName: '周*遠',
              studentCode: null,
              phone: '****3765',
              awards: '銀獎'
            },
            {
              competitionId: 8025,
              studentName: '徐*晞',
              studentCode: null,
              phone: '****6028',
              awards: '銅獎'
            },
            {
              competitionId: 15365,
              studentName: '徐*喬',
              studentCode: '*10',
              phone: '',
              awards: '銅獎'
            },
            {
              competitionId: 7223,
              studentName: '馮*霖',
              studentCode: null,
              phone: '****1202',
              awards: '銅獎'
            },
            {
              competitionId: 12588,
              studentName: '林*樂',
              studentCode: '',
              phone: '****6295',
              awards: '優異獎'
            },
            {
              competitionId: 12730,
              studentName: '劉*漩',
              studentCode: '',
              phone: '****3301',
              awards: '優異獎'
            },
            {
              competitionId: 14882,
              studentName: '莊*琳',
              studentCode: '',
              phone: '****4709',
              awards: '優異獎'
            },
            {
              competitionId: 7987,
              studentName: '袁*迪',
              studentCode: '',
              phone: '****0354',
              awards: '優異獎'
            },
          ]
        },
        {
          group: 4,
          data: [
            {
              competitionId: 16313,
              studentName: '蘇*琦',
              studentCode: null,
              phone: '****9565',
              awards: '金獎'
            },
            {
              competitionId: 15823,
              studentName: '王*鈺',
              studentCode: null,
              phone: '****6662',
              awards: '銀獎'
            },
            {
              competitionId: 15471,
              studentName: '辛*凡',
              studentCode: null,
              phone: '****7998',
              awards: '銀獎'
            },
            {
              competitionId: 15905,
              studentName: '陳*淦',
              studentCode: null,
              phone: '****2129',
              awards: '銅獎'
            },
            {
              competitionId: 10708,
              studentName: '湯*田',
              studentCode: '',
              phone: '****6515',
              awards: '銅獎'
            },
            {
              competitionId: 7999,
              studentName: '高*苡',
              studentCode: null,
              phone: '****1738',
              awards: '銅獎'
            },
            {
              competitionId: 7996,
              studentName: '林*燁',
              studentCode: '',
              phone: '****8262',
              awards: '優異獎'

            },
            {
              competitionId: 7162,
              studentName: '梁*悦',
              studentCode: '',
              phone: '****7386',
              awards: '優異獎'
            },
            {
              competitionId: 15592,
              studentName: '黃*莎',
              studentCode: '',
              phone: '****0546',
              awards: '優異獎'
            },
            {
              competitionId: 13976,
              studentName: '李*宸',
              studentCode: '',
              phone: '****8520',
              awards: '優異獎'
            },
          ]
        },
        {
          group: 5,
          data: [
            {
              competitionId: 15853,
              studentName: '張*科',
              studentCode: null,
              phone: '****1230',
              awards: '金獎'
            },
            {
              competitionId: 7232,
              studentName: '呂*烽',
              studentCode: null,
              phone: '****8898',
              awards: '銀獎'
            },
            {
              competitionId: 10655,
              studentName: '陳*宇',
              studentCode: null,
              phone: '****8382',
              awards: '銀獎'
            },
            {
              competitionId: 7303,
              studentName: '施*銓',
              studentCode: null,
              phone: '****2688',
              awards: '銅獎'
            },
            {
              competitionId: 10452,
              studentName: '顏*璐',
              studentCode: '',
              phone: '****3580',
              awards: '銅獎'
            },
            {
              competitionId: 14727,
              studentName: '陳*蔚',
              studentCode: '*65',
              phone: '',
              awards: '銅獎'
            },
            {
              competitionId: 12691,
              studentName: '林*沂',
              studentCode: '',
              phone: '****5134',
              awards: '優異獎'

            },
            {
              competitionId: 10051,
              studentName: '蔣*洢',
              studentCode: '*46',
              phone: '',
              awards: '優異獎'
            },
            {
              competitionId: 9879,
              studentName: '梁*',
              studentCode: '',
              phone: '****4285',
              awards: '優異獎'
            },
            {
              competitionId: 9741,
              studentName: 'S*NI Dhwani',
              studentCode: '*02',
              phone: '',
              awards: '優異獎'
            },
          ]
        },
        {
          group: 6,
          data: [
            {
              competitionId: 10421,
              studentName: '謝*宜',
              studentCode: null,
              phone: '****7545',
              awards: '金獎'
            },
            {
              competitionId: 15150,
              studentName: '黎*源',
              studentCode: null,
              phone: '****6910',
              awards: '銀獎'
            },
            {
              competitionId: 16150,
              studentName: '王*澤',
              studentCode: null,
              phone: '****8061',
              awards: '銀獎'
            },
            {
              competitionId: 14770,
              studentName: '郭*希',
              studentCode: '*06',
              phone: '',
              awards: '銅獎'
            },
            {
              competitionId: 10422,
              studentName: '謝*怡',
              studentCode: '',
              phone: '****7545',
              awards: '銅獎'
            },
            {
              competitionId: 7233,
              studentName: '呂*浠',
              studentCode: '',
              phone: '****8898',
              awards: '銅獎'
            },
            {
              competitionId: 7733,
              studentName: '楊*',
              studentCode: '',
              phone: '****4405',
              awards: '優異獎'

            },
            {
              competitionId: 14858,
              studentName: '洪*楟',
              studentCode: '',
              phone: '****7522',
              awards: '優異獎'
            },
            {
              competitionId: 15914,
              studentName: '史*德澤',
              studentCode: '',
              phone: '****0598',
              awards: '優異獎'
            },
          ]
        },
        {
          group: 7,
          data: [
            {
              competitionId: 15347,
              studentName: '嚴*豪',
              studentCode: '*10',
              phone: '',
              awards: '金獎'
            },
            {
              competitionId: 14999,
              studentName: '林*舜',
              studentCode: '*95',
              phone: '',
              awards: '銀獎'
            },
            {
              competitionId: 15348,
              studentName: '錢*熙',
              studentCode: '*33',
              phone: '',
              awards: '銅獎'
            },
            {
              competitionId: 15344,
              studentName: '顏*東',
              studentCode: '*12',
              phone: '',
              awards: '優異獎'
            },
            {
              competitionId: 15346,
              studentName: '陳*衡',
              studentCode: '*30',
              phone: '',
              awards: '優異獎'
            },
            {
              competitionId: 15006,
              studentName: '盧*嘉',
              studentCode: '*51',
              phone: '',
              awards: '優異獎'
            },
          ]
        },
      ],
    }
  },
  computed: {},
  components: {
    headers,
    CountryCodeSelector,
    RematchModal
  },
  watch: {
    select(val) {
      if (val) {
        console.log('發送改變', val)
      }
    }
  },
  created() {},
  mounted() {
    this.tabButTwo(0)
    this.$nextTick(() => {
      setTimeout(() => {
        this.scrollLeftTo(2)
      }, 1000)
    })
  },
  methods: {
    tabButTwo(e) {
      this.selectTwo = e
      if (this.select != 2) {
        this.getScoreList(1)
      } else {
        this.queryUserList = []
        let groupId = this.selectTwo + 1
        this.finalists.forEach((item) => {
          if (item.group == groupId) {
            this.queryUserList = item.data
          }
        })
      }
      this.scrollLeftToTwo(e)
    },
    tabBut(e) {
      this.select = e
      if (this.select != 2) {
        this.getScoreList(1)
      } else {
        this.queryUserList = []
        let groupId = this.selectTwo + 1
        this.finalists.forEach((item) => {
          if (item.group == groupId) {
            this.queryUserList = item.data
          }
        })
      }
      // 触发滑动方法
      this.scrollLeftTo(e)
    },
    // 滑动
    scrollLeftTo(name) {
      const ref = `tab${name}Ref`
      const nav = this.$refs.timeSlide
      console.log('额:', this.$refs.timeSlide.offsetWidth)
      const title = this.$refs[ref][0]
      // 计算位移偏差
      const to = title.offsetLeft - (nav.offsetWidth - title.offsetWidth) / 2
      nav.scrollLeft = to
    },
    // 滑动
    scrollLeftToTwo(name) {
      const ref = `tab${name}RefTwo`
      const nav = this.$refs.tabSlideTwo
      // console.log("额:", this.$refs.tabSlideTwo.offsetWidth);
      const title = this.$refs[ref][0]
      // 计算位移偏差
      const to = title.offsetLeft - (nav.offsetWidth - title.offsetWidth) / 2
      nav.scrollLeft = to
    },
    // 切換
    chooseBut(e) {
      this.choose = e
    },
    validator_email(val) {
      const email = val.trim()
      return /^[A-Za-z0-9]+([_\-\.][A-Za-z0-9]+)*@([A-Za-z0-9\-\_]+\.)+[A-Za-z]{2,6}$/.test(
        email
      )
    },
    validator_phone(val) {
      const phone = val.trim()
      return /^[0-9]*$/.test(phone)
    },
    // 入圍按鈕提交
    finalists() {
      let params

      if (this.choose == 0) {
        params = {
          studentName: this.from.school_name,
          prefix: this.prefix,
          phone: this.from.phone,
          email: null
        }
      } else if (this.choose == 1) {
        const { schoolCode, studentCode, password } = this.from
        if (schoolCode == '' || schoolCode == null) {
          this.$toast('請輸入學校編號 !')
          return
        }
        if (studentCode == '' || studentCode == null) {
          this.$toast('請輸入學生編號 !')
          return
        }
        if (password == '' || password == null) {
          this.$toast('請輸入學生密碼 !')
          return
        }

        params = {
          schoolCode,
          studentCode,
          password
        }
      }

      if (this.choose == 0) {
        if (params.studentName == '' || params.studentName == null) {
          this.$toast('請輸入您的姓名 !')
          return
        }
        // 手機
        if (!this.validator_phone(params.phone)) {
          this.$toast('手機號格式有誤 !')
          return
        }

        if (!this.validator_phone(params.phone)) {
          this.$toast('手機號格式有誤 !')
          return
        }
        // } else if (this.choose == 1) {
        // 郵箱
        // if (!this.validator_email(params.email)) {
        //   this.$toast('電郵格式有誤 !')
        //   return
        // }
      }
      params.scoreType = this.select + 1
      this.finalistsBut = false
      this.$toast.loading({
        message: '正在查詢中...',
        duration: 0,
        forbidClick: true
      })
      // 正式環境請求
      // this.$axios
      // .post(this.$api.getPersonInfo,params)

      // 使用代理的方式請求
      // this.$axios({
      // 	url: '/api/statistics/competition/getPersonInfo',
      // 	method: 'post',
      // 	data: params
      // })
      this.$axios
        .post(this.$api.getPersonInfo, params)
        .then((response) => {
          this.finalistsBut = true
          console.log(response)
          if (response.data.code == 500) {
            this.$toast.fail(response.data.msg)
          }
          if (response.data.code == 0) {
            //请求成功
            // this.$toast.success('獲取成功');
            const {
              competitionGroup,
              score,
              studentName,
              shortlisted,
              finalStatus,
              groupId
            } = response.data.data
            this.userObj.competitionGroup = competitionGroup //組別
            this.userObj.score = score //分數
            this.userObj.studentName = studentName //名字
            this.userObj.finalStatus = finalStatus // 1是优异奖  2是入围决赛

            this.$toast.clear()
            if (shortlisted || groupId == 7) {
              this.newShortZZC = true //入圍 彈窗
              this.newShortActive = 1 //1是成功  2是失敗
            } else {
              this.newShortZZC = true //入圍 彈窗
              this.newShortActive = 2 //1是成功  2是失敗
            }
          }
        })
        .catch((error) => {
          this.finalistsBut = true
          //请求失败
          this.$toast.fail('error')
          console.log(error)
        })
    },
    getScoreList(pageNum) {
      this.$toast.loading({
        message: '正在查詢中...',
        duration: 0,
        forbidClick: true
      })
      let groupId = this.selectTwo + 1
      let scoreType = this.select + 1
      let params =
        '?groupId=' +
        groupId +
        '&scoreType=' +
        scoreType +
        '&pageNum=' +
        pageNum +
        '&pageSize=' +
        100
      if (this.select == 1) {
        params = params + '&ranking=' + 10
      }
      this.$axios
        .get(this.$api.promotionStudentList + params)
        .then((response) => {
          this.$toast.clear()
          let arr = response.data.rows
          if (pageNum === 1) {
            this.queryUserList = arr
          } else {
            this.queryUserList = [...this.queryUserList, ...arr]
          }
          // 数据最多返回100条，超出请求2次
          if (response.data.total > 100 && pageNum === 1) {
            this.getScoreList(2)
          }
        })
        .catch((error) => {
          //请求失败
          this.$toast.fail('error')
          console.log(error)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.views {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: linear-gradient(225deg, #ff8310 0%, #fe840f 100%);
  .views_content::-webkit-scrollbar {
    display: none;
  }
  .views_content {
    width: 100%;
    height: calc(100% - 0.92rem);
    overflow: scroll;

    .views_banner {
      width: 100%;
      display: grid;

      img {
        width: 100%;
      }
    }

    .viewsTab::-webkit-scrollbar {
      display: none;
    }

    .viewsBoxTab {
      width: 100%;
    }

    .viewsTab {
      width: 100%;
      padding: 0.16rem 0;
      box-sizing: border-box;
      overflow-y: scroll;
      margin-top: -6%;

      ul {
        width: 100%;
        height: 1.28rem;
        display: inline-flex;
        justify-content: flex-start;
        align-items: center;

        li {
          width: 3.14rem;
          height: 100%;
          display: flex;
          align-content: center;
          align-items: center;
          flex-wrap: wrap;
          font-family: PingFang SC-Semibold, PingFang SC;
          line-height: 0.38rem;
          flex: 0 0 auto;
          background: #d34a00;
          cursor: pointer;

          div {
            width: 100%;
            font-size: 0.32rem;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            line-height: 0.38px;
          }
        }

        .select {
          font-weight: 600;
          color: #903300;
          padding: 0.16rem 0;
          background: url('~@/assets/image/hodgepodge/tabbuttom.png') no-repeat;
          background-size: 100% 100%;

          div {
            font-size: 0.4rem !important;
            font-family: PingFang SC-Semibold, PingFang SC;
            font-weight: 600 !important;
            color: #903300;
            line-height: 0.47rem !important;
          }
        }
      }
    }

    .viewTabContent {
      width: 100%;

      .ulsTab::-webkit-scrollbar {
        display: none;
      }

      .ulsTab {
        width: 100%;
        padding: 0.32rem 0 0 0.32rem;
        box-sizing: border-box;
        overflow-y: scroll;

        ul {
          width: 100%;
          display: inline-flex;
          justify-content: flex-start;
          align-items: center;

          li {
            margin-right: 0.32rem;
            width: 2.4rem;
            height: 1.32rem;
            background: #ffffff;
            border: 0.04rem solid #ffffff;
            display: flex;
            align-content: center;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            font-family: PingFang SC-Semibold, PingFang SC;
            line-height: 0.38rem;
            flex: 0 0 auto;
            text-align: center;
            border-radius: 0.32rem;

            div {
              width: 100%;
              text-align: center;
            }

            div:nth-child(1) {
              font-size: 0.32rem;
              font-family: PingFang SC-Regular, PingFang SC;
              color: #666666;
              line-height: 0.38rem;
            }

            div:nth-child(2) {
              font-size: 0.26rem;
              font-family: PingFang SC-Regular, PingFang SC;
              color: #666666;
              line-height: 0.3rem;
              margin-top: 0.04rem;
            }
          }
          .select {
            background: #fdd472 !important;

            div:nth-child(1) {
              font-size: 0.32rem !important;
              font-family: PingFang SC-Semibold, PingFang SC;
              font-weight: 600;
              color: #903300 !important;
              line-height: 0.38rem;
            }

            div:nth-child(2) {
              font-size: 0.26rem !important;
              font-family: PingFang SC-Semibold, PingFang SC;
              font-weight: 600;
              color: #903300 !important;
              line-height: 0.3rem !important;
            }
          }
        }
      }

      .box {
        width: 100%;
        padding: 0.32rem;
        padding-top: 0.64rem;
        box-sizing: border-box;

        ul::-webkit-scrollbar {
          display: none;
        }

        ul {
          width: 100%;
          height: 70vh;
          border-radius: 0.16rem;
          background: #ffffff;
          border: 0.06rem solid #ffffff;
          box-sizing: border-box;
          overflow-x: hidden;
          position: relative;

          .wei {
            width: 100%;
            height: 0.92rem;
          }

          .weiyitop {
            width: 100%;
            height: 0.92rem;
            background: #ffb559;
            display: flex;
            position: sticky;
            left: 0;
            top: 0;

            div {
              width: 33%;
              text-align: center;
              font-size: 0.28rem;
              font-family: PingFang SC-Semibold, PingFang SC;
              font-weight: 600;
              color: #a94800;
              line-height: 0.92rem;
            }
          }

          .lis {
            width: 100%;
            height: 0.92rem;
            margin: auto;
            background: #ffffff;
            display: flex;
            border-bottom: 0.01rem;

            div {
              width: 33%;
              font-size: 0.28rem;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #333333;
              line-height: 0.92rem;
              text-align: center;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              overflow: hidden;
            }
          }
        }
        .wu {
          width: 100%;
          // height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-wrap: wrap;
          align-content: center;
          padding-top: 1rem;

          img {
            width: 4.36rem;
            margin-bottom: 0.56rem;
          }

          span {
            width: 100%;
            font-size: 0.32rem;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            line-height: 0.38rem;
          }
        }
      }
    }

    .viewsList {
      width: 100%;
      max-width: 1024px;
      margin: auto;
      margin-top: 64px;
      padding: 0.32rem;
      box-sizing: border-box;

      ul {
        width: 100%;

        li {
          width: 640px;
          height: 124px;
          margin: auto;
          background: #fff8de;
          box-shadow: 0px 0px 4px 0px rgba(126, 56, 0, 0.26);
          border: 4px solid #fff0e1;
          border-radius: 16px;
          margin-bottom: 32px;
          font-size: 24px;
          font-family: PingFang SC-Semibold, PingFang SC;
          font-weight: 600;
          color: #ff782f;
          line-height: 28px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}

.shortlisted-query {
  width: 90%;
  max-width: 600px;
  margin: 70px auto 0 auto;
  position: relative;
  .contentFrom_title {
    width: 200px;
    height: 50px;
    margin: auto;
    border-radius: 9px;
    background: url('~@/assets/image/icon/titlebg.png') no-repeat;
    background-size: 100% 100%;
    font-size: 20px;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    line-height: 50px;
    position: absolute;
    top: -25px;
    left: 0;
    right: 0;
    margin: auto;

    text-shadow: 1.5px 1.5px 0 #ff782f, -1.5px 1.5px 0 #ff782f,
      1.5px -1.5px 0 #ff782f, -1.5px -1.5px 0 #ff782f;
  }

  .plateUlsCentent6 {
    width: 100%;
    padding: 28px 12px 18px 12px;
    border-radius: 8px;
    box-sizing: border-box;
    background: #ffffff;

    ul {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      box-sizing: border-box;

      li {
        width: 100%;
        text-align: left;
        margin-bottom: 10px;

        .prompt {
          height: 30px;
          font-size: 14px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #4d4d4d;
          line-height: 10px;
          margin-bottom: 12px;
          display: flex;
          align-items: center;
          padding-left: 13px;
          box-sizing: border-box;

          div {
            margin-right: 11px;
            text-align: center;
            line-height: 30px;
            color: #999999;
          }

          .highlight {
            color: #4d4d4d !important;
            position: relative;
          }

          .highlight:after {
            content: ' ';
            width: 90%;
            height: 1px;
            background: #4d4d4d;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
          }

          span {
            font-size: 12px !important;
            color: #de2810;
          }
        }

        .lis {
          width: 100%;
          height: 34px;
          line-height: 34px;
          background: #f5f5f5;
          display: flex;
          border-radius: 6px 6px 6px 6px;
          // overflow: hidden;

          .lisLife {
            display: flex;
            align-items: center;
            font-family: PingFang SC-Regular, PingFang SC;
            color: #4a4a4a;
            padding-left: 10px;

            img {
              width: 16px;
              height: 11px;
            }
          }

          .lisRight {
            width: 100%;
            height: 100%;
            padding-left: 13px;
            box-sizing: border-box;
            border: none;
            background: #f5f5f5;
            font-size: 14px;
            font-family: PingFang SC-Regular, PingFang SC;
          }
          input::-webkit-input-placeholder {
            color: #999999;
          }
          /* 使用webkit内核的浏览器 */
          input:-moz-placeholder {
            color: #999999;
          }
          /* Firefox版本4-18 */
          input::-moz-placeholder {
            color: #999999;
          }
          /* Firefox版本19+ */
          input:-ms-input-placeholder {
            color: #999999;
          } /* IE浏览器 */
        }
        .row-input {
          display: flex;
          align-items: center;
          font-size: 0px;
        }
        .input-name {
          width: 22%;
          // height: 30px;
          font-size: 14px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #4d4d4d;
          padding-left: 13px;
        }
        .input-name-1 {
          width: 25%;
          // height: 30px;
          font-size: 14px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #4d4d4d;
          padding-left: 13px;
        }
        .promptInput {
          width: 100%;
          // width: 78%;
          box-sizing: border-box;
          padding-left: 13px;
          height: 34px;
          font-size: 14px;
          line-height: 34px;
          background: #f5f5f5;
          border: none;
          font-family: PingFang SC-Regular, PingFang SC;
        }

        .promptInput2 {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          // width: 75%;
          height: 34px;
          line-height: 34px;
          box-sizing: border-box;
          background: #f5f5f5;
          span {
            width: 100%;
            height: 100%;
          }
          .span_tw {
            color: rgb(153, 153, 153) !important;
          }
          .promptInputValue {
            width: 100%;
            height: 100%;
            padding-left: 13px;
            box-sizing: border-box;
            border: none;
            background: #f5f5f5;
            border-radius: 6px 6px 6px 6px;
            font-size: 14px;
            font-family: PingFang SC-Regular, PingFang SC;
          }
        }
      }
    }

    .uls:first-child {
      margin-right: 60px;
    }

    .but {
      width: 140px;
      height: 40px;
      background: url('~@/assets/image/icon/but.png') no-repeat;
      background-size: 100%;
      text-align: center;
      line-height: 50px;
      font-size: 16px;
      font-weight: bold;
      color: #fff;
      padding-bottom: 20px;
      margin: 20px auto 0 auto;
      transition: all 0.3s;
      &:active {
        transform: scale(1.02);
      }
    }
  }
}

.newShortBox {
  width: 330px;
  // height: 308px;
  padding-top: 24px;
  padding-bottom: 24px;
  box-sizing: border-box;
  background: #ffffff;
  border-radius: 16px 16px 16px 16px;

  .list {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;

    .lis {
      width: 100%;
      margin-bottom: 20px;

      img {
        width: 149px;
      }
    }

    .lis:last-child {
      margin-bottom: 0px !important;
    }
    .lisText2 {
      display: flex;
      justify-content: space-between;
      width: 80%;
      margin: auto;
      font-size: 14px;

      div {
        p {
          margin-bottom: 10px;
        }
      }

      span {
        color: #ff5a25;
      }
    }

    .lisCenten {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .ulst {
      width: 86%;
      margin: auto;

      ul {
        display: flex;
        justify-content: space-between;
        width: 100%;
        border-radius: 3px 3px 3px 3px;

        li {
          width: 33.3%;
          text-align: center;
          font-size: 12px;
        }
        li:nth-child(2) {
          width: 30% !important;
        }
      }

      ul:nth-child(1) {
        background: #ff5a25;
        height: 30px;
        line-height: 30px;

        li {
          height: 100%;
          color: #fff;
        }
      }

      ul:nth-child(2) {
        background: #feffeb;
        font-size: 9.8px !important;
        line-height: 20px;
        margin-top: 10px;
        margin-bottom: 20px;

        li {
          color: #4e4e4e;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .lisText {
      font-size: 14px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #4e4e4e;
      line-height: 24px;
      padding-right: 25px;
      padding-left: 25px;
      box-sizing: border-box;
    }

    .but {
      width: 140px;
      height: 40px;
      background: url('~@/assets/image/icon/but.png') no-repeat;
      background-size: 100%;
      text-align: center;
      line-height: 36px;
      font-size: 14px;
      font-weight: bold;
      color: #fff;
      margin: auto;
    }
  }
}

.view_guide {
  margin: 0 auto;
  padding: 0 12px;
  line-height: 32px;
  background: #ff7e18;
  font-size: 16px;
  font-family: PingFang SC-Semibold, PingFang SC;
  border-radius: 4px;
  color: #ffffff;
}

/deep/ .CountryCodeSelectorContainer {
  width: 100px;
  .arrow-down {
    width: 12px;
    height: 12px;
  }
}
</style>
